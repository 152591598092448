<!--
 * @Description: 用户端-首页-停车缴费-停车代缴-在线订单 payForBehalfOnline
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-05 19:14:54
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <!-- <div ref="container"
           class="container">
        <van-sticky :container="container">
          <van-button type="warning"
                      style="height:100px;width:40px;"
                      @click="toOrderComplain">订单申诉</van-button>
        </van-sticky>
      </div> -->
      <moveBox right="12px"
               top="76px"
               endRight="12px">
        <van-button type="warning"
                    style="height:100px;width:40px;"
                    @click="toOrderComplain">订单申诉</van-button>
      </moveBox>
      <van-row class="infoBox">
        <van-row class="infoTitle">
          订单详情
        </van-row>
        <!-- <van-row class="line" /> -->
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <!-- <van-row class="infoLine">
          <van-col class="name">结束时间</van-col>
          <van-col class="details">{{orderList.orderCreateTime}}</van-col>
        </van-row> -->
        <van-row class="infoLine">
          <van-col class="name">计费规则</van-col>
          <van-col class="details"
                   style="color:blue"
                   @click="queryBillingRuleDetail">查看计费规则详情</van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount}}</span>元
          </van-col>
        </van-row>
      </van-row>
      <van-row>
        <van-button type="info"
                    class="payBtn"
                    @click="handleClickPay">支付</van-button>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import duration from '@/utils/duration'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      // 订单详情列表
      orderList: {},
      // 路由车牌号
      plateNumber: '',
      // 订单申诉
      container: null

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getOrderList()
    this.container = this.$refs.container
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取订单详情列表
    getOrderList () {
      const info = {
        plateNumber: this.$route.query.plateNumber
      }
      this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
        // console.log('fanhfan', res)
        this.orderList = res.resultEntity
        this.orderList.receivableMoneyAmount = (this.orderList.receivableMoneyAmount / 100).toFixed(2)
        this.orderList.parkDuration = duration(this.orderList.parkDuration)
        this.moneyAmount = res.resultEntity.receivableMoneyAmount
        this.moneyPay = res.resultEntity.receivedMoneyAmount
        this.orderSequence = res.resultEntity.orderSequence
        this.billingRuleDefId = res.resultEntity.billingRuleDefId
      })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    },
    // 查看计费规则详情
    queryBillingRuleDetail () { this.$router.push({ name: 'billRuleDetail', query: { billingRuleDefId: this.billingRuleDefId } }) },
    // 支付按钮
    handleClickPay () {
      if (this.payMoney && this.payOrder) {
        this.$router.push({ name: 'choosePayStyle', query: { payMoney: this.payMoney, payOrder: this.payOrder } })
      }
    },
    // 跳转订单申诉
    toOrderComplain () { this.$router.push({ name: 'orderComplain', query: { orderSequence: this.orderSequence, moneyAmount: this.moneyAmount, moneyPay: this.moneyPay } }) }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 20px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      // height: 100px;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 103.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: 100%px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 150px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .payBtn {
      width: 93.6%;
      margin: 0 3.2%;
      border-radius: 5px;
      background: #19a9fc;
      font-size: 17px;
    }
  }
}
.container {
  height: 100px;
  width: 40px;
  position: absolute;
  z-index: 1;
  left: 86%;
  top: 82px;
}
</style>
